import React, { useEffect } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import AOS from 'aos'

import Layout from '../../components/Layout'
import Section from '../../components/Section'
import {bookADemoLinkSwitch} from '../../components/lib/bookADemoLinkSwitch'
import FeaturedPlatformPagesSection from '../../components/FeaturedPlatformPagesSection'
import CustomerStorySliderSection from '../../components/CustomerStorySliderSection'
import BookDemoSection from '../../components/BookDemoSection'
import TrustedBySection from '../../components/TrustedBySection'

import carpetcourt from '../../images/home2022/MyHR-Home_CarpetCourt@2x.png'
import roosters from '../../images/home2022/MyHR-Home_Roosters@2x.png'
import finpower from '../../images/home2022/MyHR-Home_finPower@2x.png'
import hse from '../../images/home2022/MyHR-Home_HSE@2x.png'
import stars from '../../images/home2022/MyHR_Home_Stars.png'
import capterra from '../../images/home2022/MyHR_Home_Capterra.png'
import google from '../../images/home2022/MyHR_Home_Google.png'

import customerStories from '../../data/customerStories'

import './book-a-demo-new.scss'
import 'aos/dist/aos.css'

class HubspotForm extends React.Component {

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);
    
    script.addEventListener('load', () => {
      if(window.hbspt) {
        window.hbspt.forms.create({
          portalId: '3837251',
          formId: '1be7da00-c34a-4cea-bdf7-2d4c494421e4',
          target: '#hubspotForm'
        })
      }
    });
  }
  
  render() {
    return (
      <div id="hubspotForm"></div>
    );
  }
}

export default () => {
  useEffect(() => {
    // Animations
    AOS.init()
  }, [])

  var locale = {}
  locale.value = "en-AU"
  const demoLink = bookADemoLinkSwitch(locale.value)

  return (
    <Layout className="BookDemo-new" title="Book a demo with MyHR | Make HR Easy" description="We'd love to learn more about your HR challenges and priorities, show you the platform and explore if MyHR is the right fit for your team and business. Book a demo free, no-obligation demo today." locale="en-AU">
      <Section className="pb-6 bg-gray" id="scrollToForm">
        <Container>
          <Row className="py-4">
            <Col md={7} className="px-4 header-banner">
              <h4>Book your personalised demo</h4>
              <h1>See how MyHR can<br />help you make HR easy</h1>
              <ul className="large">
                <li>Contracts, compliance, and Awards coverage</li>
                <li>Automated performance, payroll and onboarding</li>
                <li>Expert HR support for any employment issue</li>
              </ul>
              <div className="d-flex align-items-center flex-row justify-content-start">
                <div className="capterra d-flex align-items-center flex-row">
                  <img src={capterra} className="logo" />
                  <img src={stars} className="stars" />
                </div>
                <div className="google d-flex align-items-center flex-row">
                  <img src={google} className="logo" />
                  <img src={stars} className="stars" />
                </div>
              </div>
            </Col>
            <Col md={5} className="px-4">
              <h3 className="form-header">Fill in your details and an HR expert will call you back.</h3>
              <HubspotForm />
            </Col>
          </Row>
        </Container>
      </Section>
      
      <TrustedBySection
        items={[
          <img src={carpetcourt} className="carpetcourt" alt="Carpet Court" />,
          <img src={roosters} className="roosters" />,
          <img src="//images.ctfassets.net/euxb051oplm7/5w72c4FZqWC1Wf4nHOx7II/59badd3061cc0146b0808cf3d01fa426/WWF_Logo.png" alt="WWF" />,
          <img src={finpower} className="finpower" alt="Finpower Connect" />,
          <img src="//images.ctfassets.net/euxb051oplm7/7tVsftUJJOfWtvtgtABIdc/9929e636dfd04c5f386ee7ab5b5e9e4e/Hell_Pizza_Logo.png" alt="Hell" />,
          <img src={hse} className="hse" alt="HSE" />,
        ]}
      />

      <FeaturedPlatformPagesSection />

      <CustomerStorySliderSection
        title="Hear why SMEs across Australia choose MyHR"
        stories={[
          customerStories['roosters'],
          customerStories['hse'],
          customerStories['carpet-court']
        ]}
      />

      <BookDemoSection
        title="Start using MyHR for free"
        text={(
          <p>Not quite ready to talk to us yet? See if MyHR is the right solution for your business with a free trial.</p>
        )}
        showBookButton={false}
        trialButtonStyle="navy"
        extraButtons={(
          <span>Or, <a href="/au/pricing">compare pricing</a></span>
        )}
      />
    </Layout>
  )
}
